module.exports = {
  defaultTitle: 'Matthew Hess',
  logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
  author: 'Matthew Hess',
  url: 'https://www.matthewhess.io',
  legalName: 'Matthew Hess',
  defaultDescription: "I'm Matthew, an avid full-stack software developer!",
  socialLinks: {
    // twitter: 'http://www.twitter.com/smakosh',
    github: 'https://github.com/matthew9510',
    linkedin: 'https://www.linkedin.com/in/matthew-u-hess',
    // instagram: 'https://instagram.com/smakosh19',
    // youtube: 'https://www.youtube.com/user/smakoshthegamer',
    // google: 'https://plus.google.com/u/0/+IsmailSmakoshGhallou',
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  address: {
    city: 'San Diego',
    region: 'Region',
    country: 'United States',
    zipCode: '92115',
  },
  foundingDate: '2021',
  recaptcha_key: '6LfhSFcaAAAAAOrrEnYzBrJ8akDH4j8-MfjxWejD',
};
